const dataHelper = {
  mapDataLayerProductFields(data: any) {
    let layer = {
      item_id: data.artNr,
      item_name: data.name,
      item_brand: data.producer || '',
      item_category: data.mainCategory || '',
      item_variant: data.wban,
      price: parseFloat(data.price),
      quantity: data.quantity || 1,
      index: data.position || '',
      product_country: data.country || '',
      product_region: data.region || '',
      product_bottle_size: data.volume || '',
      product_availability: data.availability || '',
      product_unit_type: data.volumeUnit || '',
      product_reduced: data.priceReduction,
      product_promotion: data.saleUnit || '',
      personalized: data.personalized || 0,
      personalizationRank: data.personalizationRank || 0,
      marketplace: data.marketplace || false
    };
    if (data.promotion) {
      layer['creative_name'] = data.promotion.creative;
      layer['promotion_id'] = data.promotion.id;
      layer['promotion_name'] = data.promotion.name;
    }
    return layer;
  },
  mapProductToItem(data: any) {
    return {
      affiliation: data.affiliation || '',
      coupon: data.coupon || '',
      index: data.index || 0,
      price: parseFloat(data.price),
      quantity: data.quantity || 1,
      item_id: data.item_id || '',
      item_name: data.item_name || '',
      item_brand: data.item_brand || '',
      item_category: data.item_category || '',
      item_list_id: data.item_list_id || '',
      item_list_name: data.item_list_name || '',
      item_variant: data.item_variant || '',
      item_volume: data.item_volume || data.volumeAsString || '',
      item_country: data.item_country || data.product_country || '',
      item_region: data.item_region || data.product_region || '',
      item_bottle_size: data.item_bottle_size || data.product_bottle_size || '',
      item_availability: data.item_availability || data.product_availability || '',
      item_unit_type: data.item_unit_type || data.product_unit_type || '',
      item_reduced: data.item_reduced || data.product_reduced || false,
      item_sale_unit: data.item_sale_unit || data.product_saleUnit || '',
      item_personalized: data.item_personalized || data.personalized || 0,
      item_personalization_rank: data.item_personalization_rank || data.personalizationRank || 0,
      item_marketplace: data.item_marketplace || data.marketplace || false
    };
  }
}

export {dataHelper}
