<template>
  <div
    class="info-popup--root info-popup__wrapper"
    v-if="!hideWrapper"
  >
    <div
      @click="toggleInfo"
      @mouseover="mouseoverInfo"
      @mouseleave="mouseoutInfo"
      class="info-popup__info-container"
    >
      <span
        class="info-popup__info"
      />
      <span
        v-if="label || $slots['label']"
        class="info-popup__label"
      >
        {{ label }}
        <slot name="label" />
      </span>
    </div>

    <div
      class="info-popup__wrapper-background"
      v-if="visible || hover"
      @click="closePopup"
    />

    <div
      class="info-popup"
      :class="`info-popup--${popupPosition}`"
      :style="{
        width: width
      }"
      v-if="visible || hover"
    >
      <span
        class="info-popup__close"
        v-if="showCloseIcon"
        @click="closePopup"
      />
      <slot />
    </div>
  </div>
</template>

<script lang="ts">

  export default {
    name: 'InfoPopup',
    props: {
      position: {
        type: String,
        required: false,
        default: 'top-right'
      },
      showCloseIcon: {
        type: Boolean,
        required: false,
        default: false
      },
      width: {
        type: String,
        required: false,
        default: '230px'
      },
      label: {
        type: String,
        required: false,
        default: ''
      },
    },
    data() {
      return {
        visible: false,
        hover: false,
        popupPosition: this.position,
        hideWrapper: false
      }
    },
    mounted() {
      if (this.slotStringLength < 5) {
        this.hideWrapper = true;
      }
    },
    computed: {
      isTouch(): boolean {
        return 'ontouchstart' in window;
      },
      slotStringLength() {
        let slotContent = '';
        if (this.$slots.default) {
          slotContent = this.$slots.default().map(vnode => {
            if (vnode.children) {
              return vnode.children;
            } else if (vnode.props && vnode.props.innerHTML) {
              return vnode.props.innerHTML;
            }
            return '';
          }).join('');
        }
        return slotContent.length;
      }
    },
    methods: {
      toggleInfo(event: MouseEvent) {
        event.stopPropagation();
        event.preventDefault();
        this.visible = !this.visible;
        if (this.visible) {
          this.checkPopupPosition();
        } else {
          this.hover = false;
        }
      },

      closePopup(event: MouseEvent) {
        event.stopPropagation();
        event.preventDefault();
        this.visible = false;
        this.hover = false;
      },

      mouseoverInfo() {
        if (!this.isTouch) {
          this.hover = true;
          this.checkPopupPosition();
        }
      },

      mouseoutInfo() {
        if (!this.isTouch) {
          this.hover = false;
        }
      },

      checkPopupPosition() {
        this.$nextTick(() => {
          const popupElement = this.$el.querySelector('.info-popup');
          if (popupElement) {
            const rect = popupElement.getBoundingClientRect();
            if (rect.right > window.innerWidth) {
              this.popupPosition = 'bottom-left';
            }
          }
        });
      },
    }
  }
</script>
