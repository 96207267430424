<template>
  <div
    class="input text-input"
    :class="{ 'input--disabled': disabled }"
  >
    <label
      class="input__label"
      :class="{
        'input__label--hide-label': hideLabel && innerValue,
        'input__label--as-placeholder': (!focus && !innerValue) || (focus && hideLabel && !innerValue),
        'input__label--required': required
      }"
      v-if="label"
    >
      {{ inputLabel }}
    </label>
    <div
      class="input__wrapper"
      :class="{
        'input__wrapper--hidden': type === 'hidden',
        'input__wrapper--textarea': type === 'textarea',
        'input__wrapper--focus': focus,
        'input__wrapper--error': !!error,
        'input__wrapper--valid': (!error && innerValue && !focus),
      }"
    >
      <textarea
        v-if="type === 'textarea'"
        class="input__field input__field--textarea"
        :type="type"
        :name="name"
        :value="innerValue"
        ref="inputField"
        :placeholder="inputPlaceholder"
        :disabled="disabled"
        @input="updateValue"
        @blur="blurHandler"
        @focus="focusHandler"
        @keyup.down="$emit('down')"
        @keyup.up="$emit('up')"
        @keydown.tab="$emit('tab', $event)"
        @keydown.enter="$emit('enter', $event)"
        :autocomplete="autocomplete"
        :autocorrect="autocomplete"
        :[inputAttribute]="inputAttribute"
      />
      <input
        v-else
        class="input__field"
        :type="type"
        :inputmode="inputmode"
        :name="name"
        :value="innerValue"
        ref="inputField"
        :placeholder="inputPlaceholder"
        :disabled="disabled"
        @input="updateValue"
        @blur="blurHandler"
        @focus="focusHandler"
        @keyup="$emit('keyup', $event)"
        @keyup.down="$emit('down')"
        @keyup.up="$emit('up')"
        @keydown="$emit('keydown', $event)"
        @keydown.tab="$emit('tab', $event)"
        @keydown.enter="$emit('enter', $event)"
        :autocomplete="autocomplete"
        :[inputAttribute]="inputAttribute"
      >
    </div>
    <transition name="input-error--animation">
      <span
        class="input-error"
        v-if="!!error"
        v-html="error"
      />
    </transition>
  </div>
</template>

<script lang="ts">
  import {defineComponent, ref, computed, watch} from 'vue';
  import {useI18n} from 'vue-i18n';

  type InputType = 'text' | 'url' | 'password' | 'email' | 'search' | 'hidden' | 'textarea' | 'number' | 'tel';

  export default defineComponent({
    props: {
      name: {
        type: String,
        default: '',
      },
      value: {
        type: [String, Number],
        default: '',
      },
      modelValue: {
        type: [String, Number],
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
      autocomplete: {
        type: String,
        default: 'on',
      },
      hideLabel: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      required: {
        type: Boolean,
        default: false,
      },
      inputmode: {
        type: String,
        default: '',
      },
      type: {
        type: String as () => InputType,
        default: 'text',
      },
      error: {
        type: String,
        default: '',
      },
      inputAttribute: {
        type: String,
        default: '',
      },
      hideMandatoryMarker: {
        type: Boolean,
        default: false,
      },
    },

    emits: ['update:modelValue', 'input', 'focus', 'blur', 'keyup', 'down', 'up', 'keydown', 'tab', 'enter'],

    setup(props, ctx) {
      const emit = ctx.emit;
      const {t} = useI18n();


      const mandatoryOrOptionalMarker = computed(() => {
        if (props.hideMandatoryMarker || props.disabled) {
          return '';
        }
        return props.required ? t('form.mandatory_field') : t('form.optional_field');
      });
      const inputLabel = computed(() => props.label + mandatoryOrOptionalMarker.value);
      const inputPlaceholder = computed(() => {
        if (props.hideLabel) {
          return props.label + mandatoryOrOptionalMarker.value;
        }
        return focus.value ? '' : props.label + mandatoryOrOptionalMarker.value;
      });


      watch(() => props.value, (val) => {
        innerValue.value = val;
      });
      watch(() => props.modelValue, (val) => {
        innerValue.value = val;
      });

      const innerValue = ref(props.value || props.modelValue);

      function updateValue(e) {
        innerValue.value = e.target.value;
        emit('update:modelValue', innerValue.value);
        emit('input', innerValue.value);
      }


      const focus = ref(false);
      const inputField = ref<HTMLInputElement | null>(null);

      function focusInput() {
        focusHandler();
        inputField.value?.focus();
      }

      function focusHandler() {
        focus.value = true;
        emit('focus');
      }

      function blurHandler() {
        focus.value = false;
        if (
          ['text', 'number', 'tel', 'email'].indexOf(props.type) >= 0 &&
          innerValue.value?.length > 0 &&
          innerValue.value !== innerValue.value.trim()
        ) {
          innerValue.value = innerValue.value.trim();
          emit('update:modelValue', innerValue.value);
          emit('input', innerValue.value);
        }
        emit('blur');
      }

      return {
        innerValue,
        focus,
        inputField,
        updateValue,
        focusInput,
        focusHandler,
        blurHandler,
        inputLabel,
        inputPlaceholder,
      };
    }
  })
</script>
