import axios, {type AxiosError, type AxiosRequestConfig} from 'axios';
import jsGlobalConfig from '../../../globals/jsGlobalConfig';

type Method =
  | 'get' | 'GET'
  | 'delete' | 'DELETE'
  | 'head' | 'HEAD'
  | 'options' | 'OPTIONS'
  | 'post' | 'POST'
  | 'put' | 'PUT'
  | 'patch' | 'PATCH'
  | 'purge' | 'PURGE'
  | 'link' | 'LINK'
  | 'unlink' | 'UNLINK';

const axiosInstance = axios.create({
  timeout: 20000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'X-Requested-With': 'XMLHttpRequest'
  }
});

axiosInstance.interceptors.response.use(
  null,
  errorHandler
);

async function errorHandler(error: AxiosError) {
  if (error.code === 'ECONNABORTED') {
    window.logJSError('timeout', null, null, null, error);
  }
  else if (error.code === 'ERR_BAD_REQUEST' && error.response?.status === 403) {
    const config = error.config as AxiosRequestConfig & { retryCount?: number };
    if (!config.retryCount || config.retryCount <= 2) {
      window.__globalConfig.csrfToken.value = await getNewToken();

      // There are 2 methods for passing the token. Header has priority, so we update only this.
      Object.assign(
        config.headers,
        jsGlobalConfig().getCsrfTokenHeader(),
        {retryCount: config.retryCount ? config.retryCount + 1 : 1}
      );

      return axiosInstance(config);
    }
  }

  return Promise.reject(error);
}

async function getNewToken(): Promise<string> {
  const response = await axiosInstance.get('/token/');
  return response.data;
}


export default axiosInstance;
export {Method}
