import {documentHelper} from '../../utilities/js/documentHelper/documentHelper';

const checkBrowser = () => {
  const identifiedBrowser = {};
  identifiedBrowser['edge'] = /Edg/i.test(navigator.userAgent);
  identifiedBrowser['safari'] = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  identifiedBrowser['fire-fox'] = /firefox/i.test(navigator.userAgent);
  identifiedBrowser['ios'] = /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  identifiedBrowser['touch'] = 'ontouchstart' in window;

  for (const browser in identifiedBrowser) {
    if (identifiedBrowser[browser]){
      document.querySelector('body').classList.add(`is_${browser}`);

      if (browser === 'safari') {
        const versionMatch = navigator.userAgent.match(/Version\/([0-9]+)/);
        const version = versionMatch ? versionMatch[1] : null;

        if (version) {
          const versionNumber = parseInt(version.split('.')[0]);
          document.querySelector('body').classList.add(`is_safari--${versionNumber}`);
          if (versionNumber < 15) {
            document.querySelector('body').classList.add('is_safari--old');
          }
        }
      }
    }
  }
};

const checkForTestEnv = () => {
  if (window.location.href.indexOf('env=test') > -1) {
    document.querySelector('body').classList.add('is-test-env');
  }
};

checkForTestEnv();
documentHelper.ready(() => {
  checkBrowser();
});
