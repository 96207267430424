import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, normalizeProps as _normalizeProps, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["type", "name", "value", "placeholder", "disabled", "autocomplete", "autocorrect"]
const _hoisted_2 = ["type", "inputmode", "name", "value", "placeholder", "disabled", "autocomplete"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["input text-input", { 'input--disabled': _ctx.disabled }])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(["input__label", {
        'input__label--hide-label': _ctx.hideLabel && _ctx.innerValue,
        'input__label--as-placeholder': (!_ctx.focus && !_ctx.innerValue) || (_ctx.focus && _ctx.hideLabel && !_ctx.innerValue),
        'input__label--required': _ctx.required
      }])
        }, _toDisplayString(_ctx.inputLabel), 3 /* TEXT, CLASS */))
      : _createCommentVNode("v-if", true),
    _createTextVNode(),
    _createElementVNode("div", {
      class: _normalizeClass(["input__wrapper", {
        'input__wrapper--hidden': _ctx.type === 'hidden',
        'input__wrapper--textarea': _ctx.type === 'textarea',
        'input__wrapper--focus': _ctx.focus,
        'input__wrapper--error': !!_ctx.error,
        'input__wrapper--valid': (!_ctx.error && _ctx.innerValue && !_ctx.focus),
      }])
    }, [
      (_ctx.type === 'textarea')
        ? (_openBlock(), _createElementBlock("textarea", _normalizeProps({
            key: 0,
            class: "input__field input__field--textarea",
            type: _ctx.type,
            name: _ctx.name,
            value: _ctx.innerValue,
            ref: "inputField",
            placeholder: _ctx.inputPlaceholder,
            disabled: _ctx.disabled,
            onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args))),
            onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.blurHandler && _ctx.blurHandler(...args))),
            onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.focusHandler && _ctx.focusHandler(...args))),
            onKeyup: [
              _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.$emit('down')), ["down"])),
              _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.$emit('up')), ["up"]))
            ],
            onKeydown: [
              _cache[5] || (_cache[5] = _withKeys(($event: any) => (_ctx.$emit('tab', $event)), ["tab"])),
              _cache[6] || (_cache[6] = _withKeys(($event: any) => (_ctx.$emit('enter', $event)), ["enter"]))
            ],
            autocomplete: _ctx.autocomplete,
            autocorrect: _ctx.autocomplete,
            [_ctx.inputAttribute || ""]: _ctx.inputAttribute
          }), null, 16 /* FULL_PROPS */, _hoisted_1))
        : (_openBlock(), _createElementBlock("input", _normalizeProps({
            key: 1,
            class: "input__field",
            type: _ctx.type,
            inputmode: _ctx.inputmode,
            name: _ctx.name,
            value: _ctx.innerValue,
            ref: "inputField",
            placeholder: _ctx.inputPlaceholder,
            disabled: _ctx.disabled,
            onInput: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args))),
            onBlur: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.blurHandler && _ctx.blurHandler(...args))),
            onFocus: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.focusHandler && _ctx.focusHandler(...args))),
            onKeyup: [
              _cache[10] || (_cache[10] = ($event: any) => (_ctx.$emit('keyup', $event))),
              _cache[11] || (_cache[11] = _withKeys(($event: any) => (_ctx.$emit('down')), ["down"])),
              _cache[12] || (_cache[12] = _withKeys(($event: any) => (_ctx.$emit('up')), ["up"]))
            ],
            onKeydown: [
              _cache[13] || (_cache[13] = ($event: any) => (_ctx.$emit('keydown', $event))),
              _cache[14] || (_cache[14] = _withKeys(($event: any) => (_ctx.$emit('tab', $event)), ["tab"])),
              _cache[15] || (_cache[15] = _withKeys(($event: any) => (_ctx.$emit('enter', $event)), ["enter"]))
            ],
            autocomplete: _ctx.autocomplete,
            [_ctx.inputAttribute || ""]: _ctx.inputAttribute
          }), null, 16 /* FULL_PROPS */, _hoisted_2))
    ], 2 /* CLASS */),
    _createTextVNode(),
    _createVNode(_Transition, { name: "input-error--animation" }, {
      default: _withCtx(() => [
        (!!_ctx.error)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: "input-error",
              innerHTML: _ctx.error
            }, null, 8 /* PROPS */, _hoisted_3))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    })
  ], 2 /* CLASS */))
}