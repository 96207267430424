import trackingConfig from './trackingConfig';
import trackingPageTypeConfig from './trackingPageTypeConfig';
import type TrackingProductData from '../../../types/TrackingProductData';
import type TrackingPromotionData from '../../../types/TrackingPromotionData';
import type VideoViewmodel from '../../../types/VideoViewmodel';
import {TrackingService} from '../../../services/tracking/TrackingService';
import {intersectionService} from '../intersectionService/intersectionService';
import {dataHelper} from '../dataHelper/dataHelper';
import jsGlobalConfig from '../../../globals/jsGlobalConfig';

class Tracking {
  private products: { [key: string]: TrackingProductData } = {};
  private promotions: { [key: string]: TrackingPromotionData } = {};
  private videoIdsClick: string[] = [];
  private videoIdsImpression: string[] = [];
  private intersectionServiceId = 'tracking';
  private dataLayerTracking: any = {};

  init() {
    intersectionService.createObserver(this.intersectionServiceId, {threshold: .75});
    this.trackClicksByConfig(trackingConfig);
    this.trackProducts();
    this.trackPromotions();
    this.trackPageType();
    this.trackPerformance();
    this.dataLayerPushAllLegacy();
    this.dataLayerPushAll();
  }

  public fireCheckoutOnLoad(initData: any, ecommerceCheckout: any, items: any) {
    let data: any =  {
      event: initData.event,
      ecommerce: {
        currency: jsGlobalConfig().getCurrency().toUpperCase(),
        coupon: ecommerceCheckout.coupon,
        is_first_order: ecommerceCheckout.is_first_order,
        payment_type: ecommerceCheckout.payment_type,
        delivery_method: ecommerceCheckout.delivery_method,
        shipping: ecommerceCheckout.shipping,
        tax: ecommerceCheckout.tax,
        value: ecommerceCheckout.value,
      },
      uuid: initData.uuid,
      cd6: initData.cd6,
      cd9: initData.cd9,
    };

    // uuid fallback - purchase event has no uuid
    if (!initData.uuid && window.dataLayer) {
      data.uuid = window.dataLayer[0].uuid;
      data.cd6 = window.dataLayer[0].cd6;
      data.cd9 = window.dataLayer[0].cd9;
    }

    let checkout_option = data.ecommerce.checkout_option;
    if (!checkout_option) {
      // step_2: login/register/guest
      if (data.event.includes('step_2')) {
        checkout_option = 'login';
        // if location.href includes 'welcomeMode=register' then register
        if (location.href.includes('welcomeMode=register')) {
          checkout_option = 'register';
        }
      }
      // step_3: address
      if (data.event.includes('step_3')) {
        const registerAddressForm: HTMLElement = document.querySelector('.register-address-form');
        if (registerAddressForm) {
          checkout_option = 'guest';
        } else {
          // no tracking
          return;
        }
      }
    }
    if (checkout_option) {
      data.ecommerce.checkout_option = checkout_option;
    }

    if (items) {
      const mappedItems = items.map((item) => {
        return dataHelper.mapProductToItem(item);
      });
      data.ecommerce.items = mappedItems;
    }

    const selectedDeliveryMethod: HTMLElement = document.querySelector('.delivery-option-form .radio-input--selected input');
    const deliveryMethod = selectedDeliveryMethod?.getAttribute('value') || null;
    if (deliveryMethod) {
      data.ecommerce.delivery_method = deliveryMethod;
    }

    const selectedPaymentMethod: HTMLElement = document.querySelector('.payment-method-form .radio-input--selected input');
    const paymentType = selectedPaymentMethod?.getAttribute('value') || null;
    if (paymentType) {
      data.ecommerce.payment_type = paymentType;
    }

    data.ecommerce.currency = jsGlobalConfig().getCurrency().toUpperCase();

    if (ecommerceCheckout.transaction_id) {
      data.ecommerce.transaction_id = ecommerceCheckout.transaction_id;
    }

    this.fireEvent(TrackingService.EVENT_CHECKOUT_STEP, data);
  }

  public fireCheckoutStep(event, checkoutOption, value, products = null) {
    // fallback
    if (window.dataLayer && this.dataLayerTracking.uuid === undefined) {
      this.dataLayerTracking.uuid = window.dataLayer[0].uuid;
      this.dataLayerTracking.cd6 = window.dataLayer[0].cd6;
      this.dataLayerTracking.cd9 = window.dataLayer[0].cd9;
    }

    const data: any = {
      event: event,
      ecommerce: {
        currency: jsGlobalConfig().getCurrency().toUpperCase(),
      },
      uuid: this.dataLayerTracking.uuid,
      cd6: this.dataLayerTracking.cd6,
      cd9: this.dataLayerTracking.cd9,
    };

    if (checkoutOption) {
      data.ecommerce.checkout_option = checkoutOption;
    }

    if (value) {
      data.ecommerce.value = value;
    }

    if (products) {
      data.ecommerce.items = products;
    }

    const selectedDeliveryMethod: HTMLElement = document.querySelector('.delivery-option-form .radio-input--selected input');
    const deliveryMethod = selectedDeliveryMethod?.getAttribute('value') || null;
    if (deliveryMethod) {
      data.ecommerce.delivery_method = deliveryMethod;
    }

    const selectedPaymentMethod: HTMLElement = document.querySelector('.payment-method-form .radio-input--selected input');
    const paymentType = selectedPaymentMethod?.getAttribute('value') || null;
    if (paymentType) {
      data.ecommerce.payment_type = paymentType;
    }

    // TODO: remove later - map legacy fields
    data.ecommerce.checkout = {actionField: {step: event}};

    this.fireEvent(TrackingService.EVENT_CHECKOUT_STEP, data);
  }

  public fireAddToCart(data: TrackingProductData) {
    this.fireEvent(TrackingService.EVENT_ADD_TO_CART, data);
  }

  public fireRemoveFromCart(data: TrackingProductData) {
    this.fireEvent(TrackingService.EVENT_REMOVE_FROM_CART, data);
  }

  fireVideoImpression(data: VideoViewmodel, type: string, product: string = null) {
    const videoId = data.videoId;
    if (!this.videoIdsImpression.includes(videoId)) {
      this.videoIdsImpression.push(videoId);
      this.fireEvent(TrackingService.EVENT_VIDEO_IMPRESSION, {
        videoId,
        type,
        product
      });
    }
  }

  fireVideoPlay(data: VideoViewmodel, type: string, product: string = null) {
    const videoId = data.videoId;
    if (!this.videoIdsClick.includes(videoId)) {
      this.videoIdsClick.push(videoId);
      this.fireEvent(TrackingService.EVENT_VIDEO_CLICK, {
        videoId,
        type,
        product
      });
    }
  }

  public registerProduct(data: TrackingProductData) {
    const key = data.wban + '_' + data.artNr;
    this.products[key] = data;
  }

  private getProductData(wban: string, artNr: string): TrackingProductData | null {
    const key = wban + '_' + artNr;
    if (key in this.products) {
      return this.products[key];
    }

    return null;
  }

  private getProductDataByElement(element: HTMLElement): TrackingProductData | null {
    const wban = element.dataset['wban'];
    const artNr = element.dataset['artnr'];

    const data = this.getProductData(wban, artNr);
    if (!data && element.dataset['tracking']) {
      try {
        return JSON.parse(element.dataset['tracking']);
      } catch (e) {
        // silence
      }
    }

    return data || null;
  }

  public registerPromotion(data: TrackingPromotionData) {
    this.promotions[data.id] = data;
  }

  private getPromotionData(id: string): TrackingPromotionData | null {
    if (id in this.promotions) {
      return this.promotions[id];
    }

    return null;
  }

  private trackProducts() {
    const wbanSelector = '[data-wban]';
    const basketButtonClass = 'product-box__basket-button';
    const productBoxNodes: Element[] = Array.prototype.slice.call(document.querySelectorAll(wbanSelector));

    // Impressions
    let dataList: TrackingProductData[] = [];
    const onIntersection = (element: HTMLElement) => {
      const data = this.getProductDataByElement(element);
      if (data) {
        dataList.push(data);
      }
    };
    const onIntersectionLoopEnd = () => {
      if (dataList.length) {
        this.fireEvent(TrackingService.EVENT_PRODUCT_IMPRESSION, dataList);
        dataList = [];
      }
    };

    productBoxNodes.forEach((elm) => {
      intersectionService.observeElement(this.intersectionServiceId, elm, onIntersection);
    });
    intersectionService.addEventListener('onIntersectionLoopEnd', onIntersectionLoopEnd);

    // Click
    document.body.addEventListener('click', (event) => {
      const target: Element = event.target as Element;
      const linkElm = this.findTargetLink(target, wbanSelector);
      if (linkElm && !linkElm.classList.contains(basketButtonClass)) {
        const closestElm: HTMLElement = linkElm.closest(wbanSelector) as HTMLElement;
        const data = this.getProductDataByElement(closestElm);
        if (data) {
          const promotionElement= linkElm.closest('[data-promotion-data]');
          if (promotionElement) {
            const promotionData = JSON.parse(promotionElement.getAttribute('data-promotion-data'));
            data.promotion = promotionData;
          }
          this.fireEvent(TrackingService.EVENT_PRODUCT_CLICK, data);
        }
      }
    }, {passive: true});
  }

  private dataLayerPushAll() {
    const dataLayer = document.querySelectorAll('.data-layer-push');
    for (let i = 0, l = dataLayer.length; i < l; i += 1) {
      const layerElement = dataLayer[i] as HTMLElement;
      if (layerElement) {
        const layerTracking = layerElement.dataset.layerTracking;
        if (layerTracking) {
          const data = JSON.parse(layerTracking);
          this.dataLayerTracking = data;
          // TODO: remove later - map legacy fields
          if (data.ecommerce && data.ecommerce.checkout && data.ecommerce.checkout.products) {
            this.dataLayerTracking.items = data.ecommerce.checkout.products;
          }
          if (data.ecommerce && data.ecommerce.checkout) {
            this.fireCheckoutOnLoad(data, data.ecommerce.checkout, data.items);
          } else if (data.event === 'purchase') {
            this.fireCheckoutOnLoad(data, data.ecommerce, data.ecommerce.items);
          } else {
            TrackingService.dataLayerPush(data);
          }
        }
      }
    }
  }

  private dataLayerPushAllLegacy() {
    const dataLayer = document.querySelectorAll('.data-layer-push-legacy');
    for (let i = 0, l = dataLayer.length; i < l; i += 1) {
      const layerElement = dataLayer[i] as HTMLElement;
      if (layerElement) {
        const layerTracking = layerElement.dataset.layerTracking;
        if (layerTracking) {
          const data = JSON.parse(layerTracking);
          // map itemListName to item_list_name
          let products = null;
          if (data.ecommerce && data.ecommerce.purchase && data.ecommerce.purchase.products) {
            products = data.ecommerce.purchase.products;
          } else if (data.ecommerce && data.ecommerce.checkout && data.ecommerce.checkout.products) {
            products = data.ecommerce.checkout.products;
          }
          if (products) {
            products.forEach((product) => {
              if (product.itemListName) {
                product.item_list_name = product.itemListName;
                delete product.itemListName;
              }
            });
          }
          TrackingService.dataLayerPush(data);
        }
      }
    }
  }

  private trackPromotions() {
    const promotionSelector = '[data-promotion-id]';
    const promotionIdAttr = 'promotionId';
    const promotionDataAttr = 'promotionData';
    const promotionNodes: HTMLElement[] = Array.prototype.slice.call(document.querySelectorAll(promotionSelector));

    // Impressions
    const onIntersection = (element) => {
      const data = this.getPromotionData(element.dataset[promotionIdAttr]);
      if (data) {
        const promotionNameElement = element.querySelector('[data-promotion-name]');
        const productElement = element.getAttribute('data-promotion-product');
        const containerElement = element.closest('.teasergroup_teaser__items-container');
        if (element.parentElement.classList.contains('swiper-slide-duplicate')) {
          return;
        }
        if (promotionNameElement) {
          data.name = promotionNameElement.getAttribute('data-promotion-name');
        }
        if (containerElement) {
          data.slot = containerElement.getAttribute('data-promotion-slot');
        }
        if (productElement) {
          const productJson = JSON.parse(productElement);
          let product = dataHelper.mapDataLayerProductFields(productJson);
          data.items = [product];
        }
        this.fireEvent(TrackingService.EVENT_PROMOTION_IMPRESSION, data);
      }
    };

    promotionNodes.forEach((elm) => {
      const json = elm.dataset[promotionDataAttr];
      this.registerPromotion(JSON.parse(json));
      intersectionService.observeElement(this.intersectionServiceId, elm, onIntersection);
    });

    // Click
    document.body.addEventListener('click', (event) => {
      const target: Element = event.target as Element;
      const linkElm = this.findTargetLink(target, promotionSelector, '.carousel__button');
      if (linkElm) {
        const closestElm: HTMLElement = linkElm.closest(promotionSelector) as HTMLElement;
        const data = this.getPromotionData(closestElm.dataset[promotionIdAttr]);
        if (data) {
          this.fireEvent(TrackingService.EVENT_PROMOTION_CLICK, data);
        }
      }
    }, {passive: true})
  }

  private trackPageType() {
    const pageType = this.getPageTypeByConfig(trackingPageTypeConfig);
    const data = {
      event: 'page.type',
      pageType: pageType
    }
    if (pageType) {
      TrackingService.dataLayerPush(data);
    }
  }

  private trackClicksByConfig(config) {
    document.body.addEventListener('click', (event) => {
      const target: any = event.target;
      Object.keys(config).forEach((key) => {
        const entry = config[key];
        let element = target;
        let isMatch = target.matches(entry.selector);
        if (!isMatch && !target.matches(entry.exclude)) {
          element = target.closest(entry.selector);
          isMatch = element !== null;
        }
        if (isMatch) {
          const data = this.getResolvedData(entry.data, element);
          this.fireEvent(entry.event, data);
        }
      });
    }, {passive: true});
  }

  private getPageTypeByConfig(config: typeof trackingPageTypeConfig) {
    let pageType: string;
    config.every((entry) => {
      let element = document.querySelector(entry.selector);
      if (element) {
        pageType = entry.pageType;
        return false;
      }
      return true;
    });
    return pageType;
  }

  private getResolvedData(data: { [key: string]: any }, elm: HTMLElement): { [key: string]: any } {
    const result = {...data};
    Object.keys(result).forEach((key) => {
      if (typeof result[key] == 'function') {
        result[key] = result[key](elm);
      }
    });

    return result;
  }

  private fireEvent(eventId: string, data: any) {
    TrackingService.trigger(eventId, data);
  }

  private findTargetLink(element: Element, selector: string, excludeSelector: string = ''): Element | null {
    let result: Element = null
    if (element.matches(`${selector} a, ${selector} button`)) {
      result = element;
    } else {
      const linkElement = element.closest('a');
      if (
        linkElement &&
        (linkElement.matches(`${selector} a`) || linkElement.matches(`a${selector}`))
      ) {
        result = linkElement;
      }
    }

    if (result && excludeSelector && result.matches(excludeSelector)) {
      return null;
    }

    return result;
  }

  trackPerformance() {
    if (!('PerformanceObserver' in window)) return;

    function formatTime(time: number) {
      return time.toFixed(2);
    }

    function getElementSelector(entry: PerformanceEntry) {
      const el = 'element' in entry ? entry.element as HTMLElement : null;
      return el?.classList.value;
    }

    function getResults(list: PerformanceObserverEntryList) {
      const results: Record<string, {type: string, values: any}> = {};

      const lcpEntries = list.getEntriesByType('largest-contentful-paint');
      if (lcpEntries.length) {
        const lcpEntry = lcpEntries[lcpEntries.length - 1];
        results.lcp = {
          type: 'largest-contentful-paint',
          values: {
            element: getElementSelector(lcpEntry),
            timing: formatTime(lcpEntry.startTime)
          }
        };
      }

      const paintEntries = list.getEntriesByType('paint');
      results.paint = {
        type: 'paint',
        values: paintEntries.map((entry) => ({
          name: entry.name,
          timing: formatTime(entry.startTime)
        }))
      };

      const navEntries = list.getEntriesByType('navigation');
      if (navEntries.length) {
        const navEntry: any = navEntries[navEntries.length - 1];
        results.navigation = {
          type: 'navigation',
          values: {
            domContentLoaded: formatTime(navEntry.domContentLoadedEventEnd),
            load: formatTime(navEntry.loadEventEnd),
          }
        };
      }

      return results;
    }

    const perfObserver = new PerformanceObserver(list => {
      const data = getResults(list);
      this.fireEvent(TrackingService.EVENT_GENERIC, {
        event: 'performance',
        ...data
      });
      perfObserver.disconnect();
    });

    perfObserver.observe({type: 'largest-contentful-paint', buffered: true});
    perfObserver.observe({type: 'paint', buffered: true});
    perfObserver.observe({type: 'navigation', buffered: true});
  }
}

const tracking = new Tracking();
export default tracking;
