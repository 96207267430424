import CaptchaKeyType from '../types/CaptchaKeyType';

declare global {
  interface Window {
    __APP: any;
    __globalConfig: GlobalConfig;
  }
}

interface GlobalConfig {
  language: string,
  country: string,
  currency: string,
  csrfToken: CsrfToken,
  apiUrls: { [key: string]: any },
  onRequestLightBoxUrl: string,
  cookieLightBoxUrl: string,
  assetPath: string,
  fallbackImage: string,
  fbAppId?: string,
  inputAnonymization: string,
  sessionTimeout: { [key: string]: string },
  trustedShopUrl?: string,
  trustedShopId?: string,
  klarnaLibSrc?: string,
  klarnaClientId?: string,
  wishlistUrl: string,
  basketUrl: string,
  checkoutUrl: string,
  isWishlistPopupEnabled: boolean,
  addSuccessorToBasket: boolean,
  isNativeApp: boolean,
  hcaptchaConfig?: Record<CaptchaKeyType, {key: string, invisible: boolean}>,
  salutationPersonDefault?: string,
  abVariant: {[key: string]: unknown},
  showSoldoutAlternatives?: boolean,
  nativeAppConfig?: {
    isNativeApp: boolean,
    mobileEngageContactId?: string,
  },
  partnerUrl?: string,
  cspNonce?: string,
  toplevelCategoryId: string
}

interface CsrfToken {
  name: string,
  value: string,
}

let config: JsGlobalConfig;

class JsGlobalConfig {
  get apiUrls() {
    return window.__globalConfig.apiUrls;
  }

  getCsrfTokenData() {
    let data = {};
    data[window.__globalConfig.csrfToken.name] = window.__globalConfig.csrfToken.value;
    return data;
  }

  getCsrfTokenHeader() {
    return {
      'X-CSRF-TOKEN': window.__globalConfig.csrfToken.value,
    }
  }

  getLanguage() {
    return window.__globalConfig.language || '';
  }

  getCountry() {
    return window.__globalConfig.country || '';
  }

  getCurrency() {
    return window.__globalConfig.currency || '';
  }

  getAssetPath() {
    return window.__globalConfig.assetPath || '';
  }

  getFallbackImage() {
    return window.__globalConfig.fallbackImage || '';
  }

  getInputAnonymization() {
    return window.__globalConfig.inputAnonymization || '';
  }

  getSessionTimeoutData() {
    return window.__globalConfig.sessionTimeout || {}
  }

  getWishlistUrl() {
    return window.__globalConfig.wishlistUrl;
  }

  getBasketUrl() {
    return window.__globalConfig.basketUrl;
  }

  getCheckoutUrl() {
    return window.__globalConfig.checkoutUrl;
  }

  isWishlistPopupEnabled() {
    return window.__globalConfig.isWishlistPopupEnabled;
  }

  addSuccessorToBasket() {
    return window.__globalConfig.addSuccessorToBasket;
  }

  getSalutationPersonDefault() {
    return window.__globalConfig.salutationPersonDefault;
  }

  getAbVariants() {
    return window.__globalConfig.abVariant;
  }

  getShowSoldoutAlternatives() {
    return window.__globalConfig.showSoldoutAlternatives;
  }

  getCaptchaConfig(type: CaptchaKeyType) {
    if (window.__globalConfig.hcaptchaConfig && type in window.__globalConfig.hcaptchaConfig) {
      return window.__globalConfig.hcaptchaConfig[type];
    }
    return null;
  }

  getPartnerUrl() {
    return window.__globalConfig?.partnerUrl;
  }

  getCspNonce() {
    return window.__globalConfig?.cspNonce;
  }

  getToplevelCategoryId() {
    return window.__globalConfig?.toplevelCategoryId;
  }

  getNativeAppConfig() {
    return window.__globalConfig.nativeAppConfig;
  }
}

function jsGlobalConfig(): JsGlobalConfig {
  if (config) {
    return config;
  } else {
    config = new JsGlobalConfig();
    return config;
  }
}

export default jsGlobalConfig;
